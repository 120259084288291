<template>
  <div class="bg">
    <li class="list-none h-20"></li>
    <div v-if="userId !== '0'" class="userCenter">
      <div v-if="!isAuthenticated">
        
        <a v-if="info[foundTokenIndex]">
          {{ info[foundTokenIndex].token }}  {{ info[foundTokenIndex].userName }}
        </a>
        <a v-else>
          <div class="loading-bar-follow"></div>
        </a>
      </div>
      <div v-else>
        <li class="list-none h-10"></li>
        

        <div class="parent">
          <UserCardInfo />
        </div>

        <div class="parent" v-if="!isInstaConfirmed && !isInstaEdit">
          <AddInstagrame @instagram-confirmed="isInstaConfirmed = true" />
        </div>

        <div v-if="!isInstaEdit && !isInstaChange">
          <div class="parent" v-if="isInstaConfirmed && instaUser && !isInstaEdit && !isInstaChange">
            <InstagramCardInfo 
              @instagram-edit="isInstaEdit = true, isInstaChange = false"
              @instagram-change="isInstaEdit = false, isInstaChange = true" />
          </div>
        </div>

        <div class="parent" v-if="isInstaEdit && !isInstaChange">
          <InstagramEdit @cancel="canceling"/>
        </div>
        <div class="parent" v-if="!isInstaEdit && isInstaChange">
          <AddInstagrame @instagram-confirmed="isInstaConfirmed = true" />
        </div>
      </div>
    </div>
    <div v-else class="transition-wrapper">
      <transition name="fade" mode="out-in">
        <component :is="currentComponent" @register-click="changeModeToLogin" @login-click="changeModeToSignIn"></component>
      </transition>
    </div>
    <div class="white-space">

    </div>
  </div>

</template>

<script>
import LoginCard from "../components/LoginCard.vue";
import SignCard from "../components/SignCard.vue";
import UserCardInfo from "../components/UserInformation.vue";
import InstagramCardInfo from "../components/InstagramCardInfo.vue";
import InstagramEdit from "../components/EditInstagram.vue";
import AddInstagrame from "../components/AddInstagrame.vue";

export default {
  name: "UserDetails",
  components: {
    LoginCard,
    SignCard,
    UserCardInfo,
    InstagramCardInfo,
    AddInstagrame,
    InstagramEdit,
  },
  data() {
    return {
      username: "",
      password: "",
      mode: 0,
      instagramUsername: "",
      profileName: "",
      phoneNumber: "",
      isAuthenticated: false,
      token: "",
      info: [],
      info2: [],
      info3: [],
      foundTokenIndex: -1,
      foundTokenInsta: -1,
      cursorX: 0,
      cursorY: 0,
      isInstaConfirmed: false,
      isInstaEdit: false,
      isInstaChange: false,
      instaUser: "",
      instaLinkHD: "",
    };
  },
  computed: {
    userId() {
      return this.$route.params.id;
    },
    currentComponent() {
      return this.mode === 0 ? 'SignCard' : 'LoginCard';
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.updateCursorPosition);
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateCursorPosition);
  },
  created() {
    this.token = localStorage.getItem('authToken');
    this.verifyUser();
    this.searchUser();
  },
  methods: {
    canceling(value){
      this.isInstaEdit = value;
      this.isInstaChange = value;
    },
    updateCursorPosition(event) {
      this.cursorX = event.clientX;
      this.cursorY = event.clientY;
    },
    disconnect() {
      try {
        localStorage.clear();  // Clear all local storage items
        console.log('Disconnected');
        this.$router.push({ name: 'Home' });  // Redirect to home page
      } catch (e) {
        console.error('Error during logout:', e);
      }
    },
    changeModeToLogin() {
      this.mode = 1;
    },
    changeModeToSignIn() {
      this.mode = 0;
    },
    async verifyUser() {
      try {
        const response = await fetch('https://swingi.pro/api.php?action=verifyUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: this.userId,
            token: this.token,
          }),
        });
        const data = await response.json();
        console.log('data : ', data);
        if (data.message === 'Authentification réussie') {
          this.isAuthenticated = true;
        } else {
          this.isAuthenticated = false;
        }
      } catch (error) {
        console.error('Error verifying user:', error);
        this.$router.push({ name: 'Home' });
      }
    },
    async searchUser() {
      var test = 0;
      try {
        const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        test = 1;
        
        this.info = await response.json();
        
        this.foundTokenIndex = this.info.data.findIndex(item => item.token === this.userId);
        console.log('info1 : ', this.foundTokenIndex);
        
        test = 2;

        if (this.foundTokenIndex !== -1) {

          if (localStorage.getItem('userName')) {
            
            console.log('userName exist', localStorage.getItem('userName'));
          } else {
            if(this.info.data[this.foundTokenIndex].userName !== '' || this.info.data[this.foundTokenIndex].userName !== null) {
              localStorage.setItem('userName', this.info.data[this.foundTokenIndex].userName);
            }
          }
          if (localStorage.getItem('proImage')) {
            
            console.log('proImage exist', localStorage.getItem('proImage'));
          } else {
            if(this.info.data[this.foundTokenIndex].profileImage !== '' || this.info.data[this.foundTokenIndex].profileImage !== null) {
              localStorage.setItem('proImage', this.info.data[this.foundTokenIndex].profileImage);
            } else {
              localStorage.setItem('proImage', '/g.png');
            }
          }

          const response2 = await fetch('https://swingi.pro/api.php?action=getInstaInfo');
        if (!response2.ok) {
          throw new Error(`HTTP error! Status: ${response2.status}`);
        }
        test = 4;
        this.info2 = await response2.json();

        console.log('info2 : ', this.info2);
        
        this.foundTokenInsta = this.info2.data.data.findIndex(item => item.token === this.userId);
        test = 5;
          

        if (this.foundTokenInsta !== -1) {
          const foundInstaToken = this.info2.data.data[this.foundTokenInsta];
          localStorage.setItem('instagramUser', foundInstaToken.token);
          localStorage.setItem('instaUser', foundInstaToken.instaUser);
          localStorage.setItem('instaLinkHD', foundInstaToken.instaImage);
          localStorage.setItem('country', foundInstaToken.country);
          localStorage.setItem('qst', foundInstaToken.qst);
          localStorage.setItem('answer', foundInstaToken.answer);
          localStorage.setItem('statisticsID', foundInstaToken.statisticsID);
          localStorage.setItem('dayTime', foundInstaToken.dayTime);

          console.log('instaLink : ', this.info2.data.data[this.foundTokenInsta].instaUser);

          this.instaUser = foundInstaToken.instaUser;
          this.instaLinkHD = foundInstaToken.instaImage;
          this.isInstaConfirmed = true;
        }
          
          
        } else {
          this.isInstaConfirmed = false;
        }
        test = 3;

        

        
      } catch (error) {
        console.error('Error searching insta: ', test, error);
        this.info = { error: `Error: ${error.message}` };
      }
    },
  },
};
</script>

<style>

.white-space {
  height: 50px;
}

.container {
  width: 100%;
  height: 50px;
  display: flex;
  
  justify-content: center;
  padding-top: 5px;
}

.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.loading-bar-follow {
  position: fixed; /* Positionne la barre par rapport à la fenêtre de visualisation */
  top: 0; /* Fixe la barre en haut de la page */
  left: 0; /* Aligne la barre à gauche */
  width: 100%; /* Barre pleine largeur */
  height: 4px;
  background-color: #ff5555; /* Couleur de la barre de chargement */
  animation: loadingFollowAnimation 2s infinite;
  border-radius: 2px;
  pointer-events: none; /* Empêche la barre de capturer les événements de la souris */
  z-index: 9999; /* Place la barre au-dessus de tous les autres éléments */
}

@keyframes loadingFollowAnimation {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}



.textContainer2 {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.button{
  margin: 5px;
  background-color: #00A3FF80;
  width: 90%;
  height: 30px;
  backdrop-filter: blur(2px);
  padding: 5px;
  border-radius: 2px;
  color: black;
  display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}


.button:hover {
  background-color: #00A3FF; /* Couleur de fond lors du survol */
  cursor: pointer;
}

.cancel{
  margin: 5px;
  background-color: #ff555580;
  width: 20%;
  height: 30px;
  backdrop-filter: blur(2px);
  padding: 5px;
  border-radius: 2px;
  color: black;
  display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}


.cancel:hover {
  background-color: #ff5555; /* Couleur de fond lors du survol */
  cursor: pointer;
}

.label2 {
  font-size: 12px;
}



.transition-wrapper {
  position: relative;
  height: 100%; /* Fixe la hauteur en fonction de vos besoins */
  width: 100%;   /* Fixe la largeur en fonction de vos besoins */
  overflow: hidden;
  display: flex;
    justify-content: center;
    align-items: center;
}

.fade-leave-active{
  transition: opacity 0.35s, transform 0.4s;
}


.fade-leave-to {
  opacity: 0;
  transform: translateX(20%);
}

.fade-leave {
  opacity: 1;
  transform: translateX(0%);
}
</style>


