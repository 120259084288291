<template>
    <div class="body">
        <div class="title">
            <h1>Instagrame Information :</h1>
          </div>

          <div class="child1">

            
              <div class="text">
                {{ "Instagrame User Name :" }}
            </div>
            <div class="input">
              <input v-model="username" placeholder="user_name" />
            </div>
            <div class="showPicturBox">
              <img src="/icons/exp1.jpg" alt="User Icon" class="picture"/>
            </div>
            <div class="boxBtn">
              <button class="btn" @click="searchInsta">
                <div v-if="!searching" class="btnCtn">
                  <div class="nameBtn">
                    {{ serachInstaName }}
                  </div>
                  
                  <div class="costBtn">
                    {{ sPCost }}
                  </div>

                  <div class="svg2">
                    <svg fill="#ff5555" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
                      <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                      
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                      
                      <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                      
                      </svg>
                  </div>
                </div>
                <div v-if="searching">
                  <div class="nameBtn">
                    {{ searchingLog }}
                  </div>
                </div>
                  
              </button>
          </div>
          <div class="instaView">
            <div v-if="!isError" class="boxInsta">
              
                
                <img :src="proxyImage(info.profile_pic_url_hd)" alt="Profile Picture" v-if="info.profile_pic_url_hd" ref="profileImage" class="iProfile" >
                <p class="text">{{ info.full_name }}</p>
              
            </div>
            <div v-if="isError" class="boxInsta">
              <p class="text">{{ errorMsg }}</p>
            </div>
          </div>
            

            
    
            
            
          </div>
          

          

          <div class="boxBtn2">
            <button class="btn2" @click="confirmInsta" >Confirm my Instagram</button>
          </div>
            
            
 

    </div>

    
</template>


<script>
    export default {
    name: "AddInstagrame",

    data() {
    return {
      username: '',
      info: 'test',
      canGo: false,
      isInstaConfirmed: false,
      instaUrl: localStorage.getItem('instaLinkHD'),
      serachInstaName: 'search',
      sPCost: 0,
      searching: false,
      searchingLog: 'searching',
      isError: false,
      errorMsg: '',
    };
  },

  created() {
    this.getArticlesCost();
  },

  methods: {
    async searchInsta() {
  this.searching = true;

  var statut = 0;
  
  const token = localStorage.getItem('authToken'); // Remplace ceci par le vrai token de l'utilisateur
  try {
    const response = await fetch(`https://swingi.pro/api.php?action=searchInsta&username=${this.username}&token=${token}`);
    if (!response.ok) {
      statut = response.status;
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    this.isError = false;
    const data = await response.json();
    this.info = data.data;
    this.sPoint = this.sPoint - 50;
  } catch (error) {
    this.info = { error: `Error: ${error.message}` };
    this.isError = true;
    if (statut === 403) {
      this.errorMsg = 'You D\'ont Have Enought sPoint';
    }
    
  }

  this.searching = false;
},



    async addInsta() {
      
      const userDetails = {
    token: localStorage.getItem('authToken'),
    userName: this.info.full_name,
    instaImage: this.instaUrl,
    country: "country",
    qst: "qst",
    answer: "answer",
    statisticsID: "statisticsID",
    dayTime: new Date().toISOString()
  };
      try {
      
        if (localStorage.getItem('authToken') != "") {
          console.log('Token found : ',localStorage.getItem('authToken'));
          console.log('userName found : ',this.info.full_name);
          console.log('instaImage found : ',this.instaUrl);
      await fetch('https://swingi.pro/api.php?action=saveInstaDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userDetails)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log('Réponse POST:', data);
          if (data.error) {
            console.error('Erreur:', data.error, 'Détails:', data.details);
          } else {
            console.log('Succès:', data.message);
            
          }
        })
        .catch(error => {
          console.error('Erreur lors de la requête POST:', error);
        });
        }
        this.canGo = true;
      } catch (error) {
        this.info = { error: `Error: ${error.message}` };
      }
    },

    async confirmInsta(){
      if (this.info && this.info.full_name) {
        console.log("insta confirmed : ", this.info.profile_pic_url_hd);

        
        await this.saveInstaProfile(this.info.profile_pic_url_hd);
        
        await this.addInsta();
        if (this.canGo == true){
          this.isInstaConfirmed = true;
          localStorage.setItem('instagramUser', localStorage.getItem('authToken'));
          localStorage.setItem('instaUser', this.info.full_name);
          localStorage.setItem('instaLinkHD', this.instaUrl);
          localStorage.setItem('country', "country");
          localStorage.setItem('qst', "qst");
          localStorage.setItem('answer', "answer");
          localStorage.setItem('statisticsID', "statisticsID");
          localStorage.setItem('dayTime', new Date().toISOString());
          this.$emit('instagram-confirmed');
        }
        
  }
    },

    proxyImage(url) {
      return `https://swingi.pro/api.php?action=proxyImage&url=${encodeURIComponent(url)}`;
    },

    async saveInstaProfile(url) {
    const token = localStorage.getItem('authToken');

    if (!token) {
        console.error('Token non trouvé');
        return;
    }

    const instaDetails = {
        url: url,
        token: token
    };

    try {
        // Convertir les détails en URLSearchParams
        const params = new URLSearchParams(instaDetails);

        // Envoyer la requête POST
        const uploadResponse = await fetch(`https://swingi.pro/api.php?action=uploadInstaPro&${params.toString()}`, {
            method: 'POST'
        });

        if (!uploadResponse.ok) {
            throw new Error('Erreur lors de la requête POST');
        }

        const data = await uploadResponse.json();

        if (data.error) {
            console.error('Erreur:', data.error);
        } else {
            console.log('Image sauvegardée avec succès:', data.filePath);
            this.instaUrl = data.filePath;
            // Utilisez l'URL de l'image sauvegardée ici
        }
    } catch (error) {
        console.error('Erreur lors de la requête POST:', error);
    }
},
async getArticlesCost() {
  try {
    
    const response = await fetch(`https://swingi.pro/api.php?action=getArticlesCost`, {
      method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (data.error) {
      console.error('Erreur:', data.error, 'Détails:', data.details);
    } else {
      
      if (data.success === false){
        console.log('data.success :', data.data[0].name );
      } else {

          this.serachInstaName = data.data[0].name;
          this.sPCost = data.data[0].cost;
        
      }
    }


  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }
},





  }
}
</script>

<style>
.body {

    width: 80%; /* Prendre toute la largeur du parent */
    border-radius: 6px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, .8);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);

  }

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 12px; /* Ajuster la taille de la police selon vos besoins */
    color: black; /* Ajuster la couleur du texte selon vos besoins */
  }

  .showPicturBox {
    display: flex;
    justify-content: center; /* Center horizontally */
    width: 100%;
    max-height: 150px;
  }

  .picture {
    padding: 10px;
    height: 25vh;
  }

  .iProfile {
    margin-right: 10px;
    height: 30px;
    border-radius: 50%; /* Rend l'image circulaire */
  }


  .text {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-size: 12px; /* Ajuster la taille de la police selon vos besoins */
    color: black; /* Ajuster la couleur du texte selon vos besoins */
  }

  .child1 {
    width: 100%;
    padding: 10px;


  }

  .instaView {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .boxInsta {
    width: 100%;
    height: 30px;
    background: rgba(212, 212, 212, 0.6);
    border-radius: 2px;
    display: flex;
    justify-self: center;
    align-items: center;

  }

  .input {
    width: 100%;
    height: 35px;

  }
  
  .input input:hover, 
  .input input:focus {
    width: 100%;
    height: 30px;
    background: rgba(255, 255, 255, .9);
    border: none;
    outline: none;
    border-radius: 2px;
    padding-left: 2px;
    font-size: 12px; /* Changez la taille de la police ici */
  }

  .input input:not(:focus) {
    padding-left: 2px;
    font-size: 12px; /* Taille de police lorsque l'élément perd le focus */
  }
  
  .input input::placeholder {
    padding-left: 2px;
    color: gray;
    font-size: 12px; /* Changez la taille de la police du placeholder ici */
  }



  .boxBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
    font-size: 13px;
  }
  
  .btn:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }
  .boxBtn2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .btn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    font-size: 13px;
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
  }
  
  .btn2:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }


  .input input {
    width: 100%;
    height: 30px;
    background: rgba(212, 212, 212, 0.6);
    border: none;
    outline: none;
    border-radius: 2px;
    font-size: 16px;

  }

  .svg2 {
    width: 10%;
    min-width: 20px;
    display: flex;
    align-items: center;
  }

  .nameBtn {
  
    margin-right: 5px;
    width: 70%;
    min-width: 50px;
    display: flex;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
  }

  .costBtn {
  
    margin-left: 5px;
    width: 20%;
    min-width: 30px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
  }

  .btnCtn {
    display: flex;
    justify-content: center;
  }

</style>