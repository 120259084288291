<template>
  <div class="body3">
    <p class="instaTitle">Instagram</p>
    <div class="instaViewBox">
      <div class="instaImage">
        <img :src="instaImage" alt="Profile Picture" class="profileImage">
      </div>
      <div class="instaName">
        <p class="userTitle">{{ instaUser }}</p>
      </div>
    </div>

    <div v-if="isEditing">
      <form>
        <ul>
          <li>
            <div class="structure-box">
              <div class="dscr">
                <text>Your Insta Account Country :</text>
              </div>           
             
              <div class="dropdown">
                <div class="dropbtn" @click="toggleDropdown">{{ dDContent }}</div>
                <div class="dropdown-content" v-if="isDropdownOpen">
                  <a v-for="country in countries" :key="country.name" @click.prevent="updateContent(country.name)">
                    <img :src="country.flag" alt="Flag of {{ country.name }}" class="flag-icon" />
                    {{ country.name }}
                  </a>
                </div>
              </div>
            </div>
            

            <div class="structure-box">
              <div class="dscr">
                <text>Searching Description :</text>
              </div>
              <div class="short-text">
                <text> - Whrite Short Description To Explane What To Search For Getting Trafics On Your Account</text>
              </div>
              <div class="short-text">
                <text>ex: search on instagrame for how to get money for free with Swingi</text>
              </div>  
             
              <input
                type="text"
                ref="autoResizeInput"
                v-model="sDescription"
                @input="autoResizeInput"
              />
            </div>

            <div class="structure-box">
              <div class="dscr">
                <text>Simple Quastion :</text>
              </div>
              <div class="short-text">
                <text>- Whrite Simple Question Basing On Your Searching Description</text>
              </div>  
              <div class="short-text">
                <text>ex: in my video how many times I say Swingi</text>
              </div>  
             
              <input
                type="text"
                ref="autoResizeInput"
                v-model="sQst"
                @input="autoResizeInput"
              />
            </div>

            

            

            <div class="structure-box">
              <div class="dscr">
                <text>Correct Answer :</text>
              </div>
              <div class="short-text">
                <text>- Whrite Your Correct Answer Here</text>
              </div>  
              <div class="short-text">
                <text>ex: 3 Times</text>
              </div>  
             
              <input
                type="correct-answer"
                ref="autoResizeInput"
                v-model="cAnswer"
                @input="autoResizeInput"
              />
            </div>

            

            <div class="structure-box">
              <div class="dscr">
                <text>Wrong Answer 1 :</text>
              </div>
              <div class="short-text">
                <text>- Whrite Your Wrong Answer 1 Here</text>
              </div> 
              <div class="short-text">
                <text>ex: 2 Times</text>
              </div>  
             
              <input
                type="wrong-answer"
                ref="autoResizeInput"
                v-model="wAnswer1"
                @input="autoResizeInput"
              />
            </div>

           

            <div class="structure-box">
              <div class="dscr">
                <text>Wrong Answer 2 :</text>
              </div>
              <div class="short-text">
                <text>- Whrite Your Wrong Answer 2 Here</text>
              </div> 
              <div class="short-text">
                <text>ex: Once</text>
              </div>  
             
              <input
                type="wrong-answer"
                ref="autoResizeInput"
                v-model="wAnswer2"
                @input="autoResizeInput"
              />
            </div>

            <div class="structure-box2">
              <!-- Utilisation de v-model pour lier la valeur de la checkbox -->
              <CheckBox v-model="isChecked" label="Activate After Saving" @input="isAccepted" />

            </div>



            

          </li>
        </ul>

        <div class="structure-box2">
          <div class="button" @click="saveInstaQst">
            Save
          </div>
          <div class="cancel" @click="cancel">
            Cancel
          </div>
        </div>

        <div class="structure-box2">
          <div v-if="errorInputing" class="errorText">
            {{ textErrorInputing }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import CheckBox from "../components/CheckBox.vue";

export default {
  name: "InstagramEdit",
  components: {
    CheckBox,
    
  },

  data() {
    return {
      instaUser: localStorage.getItem('instaUser'),
      instaImage: localStorage.getItem('instaLinkHD'),
      isInstaEdit: false,
      isInstaChange: false,
      isInstaConfirmed: false,
      sQst: '', // Add this line to store input value
      sDescription: '', // Add this line to store input value
      cAnswer: '', // Add this line to store input value
      wAnswer1: '', // Add this line to store input value
      wAnswer2: '', // Add this line to store input value
      isEditing: true,
      dDContent: 'World',
      isDropdownOpen: false,
      errorInputing: false,
      textErrorInputing: 'Pleas make sur ther was no empty Value',
      isChecked: this.value,
      instaQst: [],
      
      countries: [
        { name: 'World', flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Flag_of_FOTW.svg/1200px-Flag_of_FOTW.svg.png' },
  { name: 'Afghanistan', flag: 'https://flagpedia.net/data/flags/h80/af.png' },
  { name: 'Albania', flag: 'https://flagpedia.net/data/flags/h80/al.png' },
  { name: 'Algeria', flag: 'https://flagpedia.net/data/flags/h80/dz.png' },
  { name: 'Andorra', flag: 'https://flagpedia.net/data/flags/h80/ad.png' },
  { name: 'Angola', flag: 'https://flagpedia.net/data/flags/h80/ao.png' },
  { name: 'Antigua and Barbuda', flag: 'https://flagpedia.net/data/flags/h80/ag.png' },
  { name: 'Argentina', flag: 'https://flagpedia.net/data/flags/h80/ar.png' },
  { name: 'Armenia', flag: 'https://flagpedia.net/data/flags/h80/am.png' },
  { name: 'Australia', flag: 'https://flagpedia.net/data/flags/h80/au.png' },
  { name: 'Austria', flag: 'https://flagpedia.net/data/flags/h80/at.png' },
  { name: 'Azerbaijan', flag: 'https://flagpedia.net/data/flags/h80/az.png' },
  { name: 'Bahamas', flag: 'https://flagpedia.net/data/flags/h80/bs.png' },
  { name: 'Bahrain', flag: 'https://flagpedia.net/data/flags/h80/bh.png' },
  { name: 'Bangladesh', flag: 'https://flagpedia.net/data/flags/h80/bd.png' },
  { name: 'Barbados', flag: 'https://flagpedia.net/data/flags/h80/bb.png' },
  { name: 'Belarus', flag: 'https://flagpedia.net/data/flags/h80/by.png' },
  { name: 'Belgium', flag: 'https://flagpedia.net/data/flags/h80/be.png' },
  { name: 'Belize', flag: 'https://flagpedia.net/data/flags/h80/bz.png' },
  { name: 'Benin', flag: 'https://flagpedia.net/data/flags/h80/bj.png' },
  { name: 'Bhutan', flag: 'https://flagpedia.net/data/flags/h80/bt.png' },
  { name: 'Bolivia', flag: 'https://flagpedia.net/data/flags/h80/bo.png' },
  { name: 'Bosnia and Herzegovina', flag: 'https://flagpedia.net/data/flags/h80/ba.png' },
  { name: 'Botswana', flag: 'https://flagpedia.net/data/flags/h80/bw.png' },
  { name: 'Brazil', flag: 'https://flagpedia.net/data/flags/h80/br.png' },
  { name: 'Brunei', flag: 'https://flagpedia.net/data/flags/h80/bn.png' },
  { name: 'Bulgaria', flag: 'https://flagpedia.net/data/flags/h80/bg.png' },
  { name: 'Burkina Faso', flag: 'https://flagpedia.net/data/flags/h80/bf.png' },
  { name: 'Burundi', flag: 'https://flagpedia.net/data/flags/h80/bi.png' },
  { name: 'Cabo Verde', flag: 'https://flagpedia.net/data/flags/h80/cv.png' },
  { name: 'Cambodia', flag: 'https://flagpedia.net/data/flags/h80/kh.png' },
  { name: 'Cameroon', flag: 'https://flagpedia.net/data/flags/h80/cm.png' },
  { name: 'Canada', flag: 'https://flagpedia.net/data/flags/h80/ca.png' },
  { name: 'Central African Republic', flag: 'https://flagpedia.net/data/flags/h80/cf.png' },
  { name: 'Chad', flag: 'https://flagpedia.net/data/flags/h80/td.png' },
  { name: 'Chile', flag: 'https://flagpedia.net/data/flags/h80/cl.png' },
  { name: 'China', flag: 'https://flagpedia.net/data/flags/h80/cn.png' },
  { name: 'Colombia', flag: 'https://flagpedia.net/data/flags/h80/co.png' },
  { name: 'Comoros', flag: 'https://flagpedia.net/data/flags/h80/km.png' },
  { name: 'Congo (Congo-Brazzaville)', flag: 'https://flagpedia.net/data/flags/h80/cg.png' },
  { name: 'Costa Rica', flag: 'https://flagpedia.net/data/flags/h80/cr.png' },
  { name: 'Croatia', flag: 'https://flagpedia.net/data/flags/h80/hr.png' },
  { name: 'Cuba', flag: 'https://flagpedia.net/data/flags/h80/cu.png' },
  { name: 'Cyprus', flag: 'https://flagpedia.net/data/flags/h80/cy.png' },
  { name: 'Czechia', flag: 'https://flagpedia.net/data/flags/h80/cz.png' },
  { name: 'Democratic Republic of the Congo', flag: 'https://flagpedia.net/data/flags/h80/cd.png' },
  { name: 'Denmark', flag: 'https://flagpedia.net/data/flags/h80/dk.png' },
  { name: 'Djibouti', flag: 'https://flagpedia.net/data/flags/h80/dj.png' },
  { name: 'Dominica', flag: 'https://flagpedia.net/data/flags/h80/dm.png' },
  { name: 'Dominican Republic', flag: 'https://flagpedia.net/data/flags/h80/do.png' },
  { name: 'Ecuador', flag: 'https://flagpedia.net/data/flags/h80/ec.png' },
  { name: 'Egypt', flag: 'https://flagpedia.net/data/flags/h80/eg.png' },
  { name: 'El Salvador', flag: 'https://flagpedia.net/data/flags/h80/sv.png' },
  { name: 'Equatorial Guinea', flag: 'https://flagpedia.net/data/flags/h80/gq.png' },
  { name: 'Eritrea', flag: 'https://flagpedia.net/data/flags/h80/er.png' },
  { name: 'Estonia', flag: 'https://flagpedia.net/data/flags/h80/ee.png' },
  { name: 'Eswatini', flag: 'https://flagpedia.net/data/flags/h80/sz.png' },
  { name: 'Ethiopia', flag: 'https://flagpedia.net/data/flags/h80/et.png' },
  { name: 'Fiji', flag: 'https://flagpedia.net/data/flags/h80/fj.png' },
  { name: 'Finland', flag: 'https://flagpedia.net/data/flags/h80/fi.png' },
  { name: 'France', flag: 'https://flagpedia.net/data/flags/h80/fr.png' },
  { name: 'Gabon', flag: 'https://flagpedia.net/data/flags/h80/ga.png' },
  { name: 'Gambia', flag: 'https://flagpedia.net/data/flags/h80/gm.png' },
  { name: 'Georgia', flag: 'https://flagpedia.net/data/flags/h80/ge.png' },
  { name: 'Germany', flag: 'https://flagpedia.net/data/flags/h80/de.png' },
  { name: 'Ghana', flag: 'https://flagpedia.net/data/flags/h80/gh.png' },
  { name: 'Greece', flag: 'https://flagpedia.net/data/flags/h80/gr.png' },
  { name: 'Grenada', flag: 'https://flagpedia.net/data/flags/h80/gd.png' },
  { name: 'Guatemala', flag: 'https://flagpedia.net/data/flags/h80/gt.png' },
  { name: 'Guinea', flag: 'https://flagpedia.net/data/flags/h80/gn.png' },
  { name: 'Guinea-Bissau', flag: 'https://flagpedia.net/data/flags/h80/gw.png' },
  { name: 'Guyana', flag: 'https://flagpedia.net/data/flags/h80/gy.png' },
  { name: 'Haiti', flag: 'https://flagpedia.net/data/flags/h80/ht.png' },
  { name: 'Honduras', flag: 'https://flagpedia.net/data/flags/h80/hn.png' },
  { name: 'Hungary', flag: 'https://flagpedia.net/data/flags/h80/hu.png' },
  { name: 'Iceland', flag: 'https://flagpedia.net/data/flags/h80/is.png' },
  { name: 'India', flag: 'https://flagpedia.net/data/flags/h80/in.png' },
  { name: 'Indonesia', flag: 'https://flagpedia.net/data/flags/h80/id.png' },
  { name: 'Iran', flag: 'https://flagpedia.net/data/flags/h80/ir.png' },
  { name: 'Iraq', flag: 'https://flagpedia.net/data/flags/h80/iq.png' },
  { name: 'Ireland', flag: 'https://flagpedia.net/data/flags/h80/ie.png' },
  { name: 'Israel', flag: 'https://flagpedia.net/data/flags/h80/il.png' },
  { name: 'Italy', flag: 'https://flagpedia.net/data/flags/h80/it.png' },
  { name: 'Jamaica', flag: 'https://flagpedia.net/data/flags/h80/jm.png' },
  { name: 'Japan', flag: 'https://flagpedia.net/data/flags/h80/jp.png' },
  { name: 'Jordan', flag: 'https://flagpedia.net/data/flags/h80/jo.png' },
  { name: 'Kazakhstan', flag: 'https://flagpedia.net/data/flags/h80/kz.png' },
  { name: 'Kenya', flag: 'https://flagpedia.net/data/flags/h80/ke.png' },
  { name: 'Kiribati', flag: 'https://flagpedia.net/data/flags/h80/ki.png' },
  { name: 'Kuwait', flag: 'https://flagpedia.net/data/flags/h80/kw.png' },
  { name: 'Kyrgyzstan', flag: 'https://flagpedia.net/data/flags/h80/kg.png' },
  { name: 'Laos', flag: 'https://flagpedia.net/data/flags/h80/la.png' },
  { name: 'Latvia', flag: 'https://flagpedia.net/data/flags/h80/lv.png' },
  { name: 'Lebanon', flag: 'https://flagpedia.net/data/flags/h80/lb.png' },
  { name: 'Lesotho', flag: 'https://flagpedia.net/data/flags/h80/ls.png' },
  { name: 'Liberia', flag: 'https://flagpedia.net/data/flags/h80/lr.png' },
  { name: 'Libya', flag: 'https://flagpedia.net/data/flags/h80/ly.png' },
  { name: 'Liechtenstein', flag: 'https://flagpedia.net/data/flags/h80/li.png' },
  { name: 'Lithuania', flag: 'https://flagpedia.net/data/flags/h80/lt.png' },
  { name: 'Luxembourg', flag: 'https://flagpedia.net/data/flags/h80/lu.png' },
  { name: 'Madagascar', flag: 'https://flagpedia.net/data/flags/h80/mg.png' },
  { name: 'Malawi', flag: 'https://flagpedia.net/data/flags/h80/mw.png' },
  { name: 'Malaysia', flag: 'https://flagpedia.net/data/flags/h80/my.png' },
  { name: 'Maldives', flag: 'https://flagpedia.net/data/flags/h80/mv.png' },
  { name: 'Mali', flag: 'https://flagpedia.net/data/flags/h80/ml.png' },
  { name: 'Malta', flag: 'https://flagpedia.net/data/flags/h80/mt.png' },
  { name: 'Marshall Islands', flag: 'https://flagpedia.net/data/flags/h80/mh.png' },
  { name: 'Mauritania', flag: 'https://flagpedia.net/data/flags/h80/mr.png' },
  { name: 'Mauritius', flag: 'https://flagpedia.net/data/flags/h80/mu.png' },
  { name: 'Mexico', flag: 'https://flagpedia.net/data/flags/h80/mx.png' },
  { name: 'Micronesia', flag: 'https://flagpedia.net/data/flags/h80/fm.png' },
  { name: 'Moldova', flag: 'https://flagpedia.net/data/flags/h80/md.png' },
  { name: 'Monaco', flag: 'https://flagpedia.net/data/flags/h80/mc.png' },
  { name: 'Mongolia', flag: 'https://flagpedia.net/data/flags/h80/mn.png' },
  { name: 'Montenegro', flag: 'https://flagpedia.net/data/flags/h80/me.png' },
  { name: 'Morocco', flag: 'https://flagpedia.net/data/flags/h80/ma.png' },
  { name: 'Mozambique', flag: 'https://flagpedia.net/data/flags/h80/mz.png' },
  { name: 'Myanmar', flag: 'https://flagpedia.net/data/flags/h80/mm.png' },
  { name: 'Namibia', flag: 'https://flagpedia.net/data/flags/h80/na.png' },
  { name: 'Nauru', flag: 'https://flagpedia.net/data/flags/h80/nr.png' },
  { name: 'Nepal', flag: 'https://flagpedia.net/data/flags/h80/np.png' },
  { name: 'Netherlands', flag: 'https://flagpedia.net/data/flags/h80/nl.png' },
  { name: 'New Zealand', flag: 'https://flagpedia.net/data/flags/h80/nz.png' },
  { name: 'Nicaragua', flag: 'https://flagpedia.net/data/flags/h80/ni.png' },
  { name: 'Niger', flag: 'https://flagpedia.net/data/flags/h80/ne.png' },
  { name: 'Nigeria', flag: 'https://flagpedia.net/data/flags/h80/ng.png' },
  { name: 'North Korea', flag: 'https://flagpedia.net/data/flags/h80/kp.png' },
  { name: 'North Macedonia', flag: 'https://flagpedia.net/data/flags/h80/mk.png' },
  { name: 'Norway', flag: 'https://flagpedia.net/data/flags/h80/no.png' },
  { name: 'Oman', flag: 'https://flagpedia.net/data/flags/h80/om.png' },
  { name: 'Pakistan', flag: 'https://flagpedia.net/data/flags/h80/pk.png' },
  { name: 'Palau', flag: 'https://flagpedia.net/data/flags/h80/pw.png' },
  { name: 'Palestine', flag: 'https://flagpedia.net/data/flags/h80/ps.png' },
  { name: 'Panama', flag: 'https://flagpedia.net/data/flags/h80/pa.png' },
  { name: 'Papua New Guinea', flag: 'https://flagpedia.net/data/flags/h80/pg.png' },
  { name: 'Paraguay', flag: 'https://flagpedia.net/data/flags/h80/py.png' },
  { name: 'Peru', flag: 'https://flagpedia.net/data/flags/h80/pe.png' },
  { name: 'Philippines', flag: 'https://flagpedia.net/data/flags/h80/ph.png' },
  { name: 'Poland', flag: 'https://flagpedia.net/data/flags/h80/pl.png' },
  { name: 'Portugal', flag: 'https://flagpedia.net/data/flags/h80/pt.png' },
  { name: 'Qatar', flag: 'https://flagpedia.net/data/flags/h80/qa.png' },
  { name: 'Romania', flag: 'https://flagpedia.net/data/flags/h80/ro.png' },
  { name: 'Russia', flag: 'https://flagpedia.net/data/flags/h80/ru.png' },
  { name: 'Rwanda', flag: 'https://flagpedia.net/data/flags/h80/rw.png' },
  { name: 'Saint Kitts and Nevis', flag: 'https://flagpedia.net/data/flags/h80/kn.png' },
  { name: 'Saint Lucia', flag: 'https://flagpedia.net/data/flags/h80/lc.png' },
  { name: 'Saint Vincent and the Grenadines', flag: 'https://flagpedia.net/data/flags/h80/vc.png' },
  { name: 'Samoa', flag: 'https://flagpedia.net/data/flags/h80/ws.png' },
  { name: 'San Marino', flag: 'https://flagpedia.net/data/flags/h80/sm.png' },
  { name: 'Sao Tome and Principe', flag: 'https://flagpedia.net/data/flags/h80/st.png' },
  { name: 'Saudi Arabia', flag: 'https://flagpedia.net/data/flags/h80/sa.png' },
  { name: 'Senegal', flag: 'https://flagpedia.net/data/flags/h80/sn.png' },
  { name: 'Serbia', flag: 'https://flagpedia.net/data/flags/h80/rs.png' },
      { name: 'Seychelles', flag: 'https://flagpedia.net/data/flags/h80/sc.png' },
  { name: 'Sierra Leone', flag: 'https://flagpedia.net/data/flags/h80/sl.png' },
  { name: 'Singapore', flag: 'https://flagpedia.net/data/flags/h80/sg.png' },
  { name: 'Slovakia', flag: 'https://flagpedia.net/data/flags/h80/sk.png' },
  { name: 'Slovenia', flag: 'https://flagpedia.net/data/flags/h80/si.png' },
  { name: 'Solomon Islands', flag: 'https://flagpedia.net/data/flags/h80/sb.png' },
  { name: 'Somalia', flag: 'https://flagpedia.net/data/flags/h80/so.png' },
  { name: 'South Africa', flag: 'https://flagpedia.net/data/flags/h80/za.png' },
  { name: 'South Korea', flag: 'https://flagpedia.net/data/flags/h80/kr.png' },
  { name: 'South Sudan', flag: 'https://flagpedia.net/data/flags/h80/ss.png' },
  { name: 'Spain', flag: 'https://flagpedia.net/data/flags/h80/es.png' },
  { name: 'Sri Lanka', flag: 'https://flagpedia.net/data/flags/h80/lk.png' },
  { name: 'Sudan', flag: 'https://flagpedia.net/data/flags/h80/sd.png' },
  { name: 'Suriname', flag: 'https://flagpedia.net/data/flags/h80/sr.png' },
  { name: 'Sweden', flag: 'https://flagpedia.net/data/flags/h80/se.png' },
  { name: 'Switzerland', flag: 'https://flagpedia.net/data/flags/h80/ch.png' },
  { name: 'Syria', flag: 'https://flagpedia.net/data/flags/h80/sy.png' },
  { name: 'Taiwan', flag: 'https://flagpedia.net/data/flags/h80/tw.png' },
  { name: 'Tajikistan', flag: 'https://flagpedia.net/data/flags/h80/tj.png' },
  { name: 'Tanzania', flag: 'https://flagpedia.net/data/flags/h80/tz.png' },
  { name: 'Thailand', flag: 'https://flagpedia.net/data/flags/h80/th.png' },
  { name: 'Togo', flag: 'https://flagpedia.net/data/flags/h80/tg.png' },
  { name: 'Tonga', flag: 'https://flagpedia.net/data/flags/h80/to.png' },
  { name: 'Trinidad and Tobago', flag: 'https://flagpedia.net/data/flags/h80/tt.png' },
  { name: 'Tunisia', flag: 'https://flagpedia.net/data/flags/h80/tn.png' },
  { name: 'Turkey', flag: 'https://flagpedia.net/data/flags/h80/tr.png' },
  { name: 'Turkmenistan', flag: 'https://flagpedia.net/data/flags/h80/tm.png' },
  { name: 'Tuvalu', flag: 'https://flagpedia.net/data/flags/h80/tv.png' },
  { name: 'Uganda', flag: 'https://flagpedia.net/data/flags/h80/ug.png' },
  { name: 'Ukraine', flag: 'https://flagpedia.net/data/flags/h80/ua.png' },
  { name: 'United Arab Emirates', flag: 'https://flagpedia.net/data/flags/h80/ae.png' },
  { name: 'United Kingdom', flag: 'https://flagpedia.net/data/flags/h80/gb.png' },
  { name: 'United States', flag: 'https://flagpedia.net/data/flags/h80/us.png' },
  { name: 'Uruguay', flag: 'https://flagpedia.net/data/flags/h80/uy.png' },
  { name: 'Uzbekistan', flag: 'https://flagpedia.net/data/flags/h80/uz.png' },
  { name: 'Vanuatu', flag: 'https://flagpedia.net/data/flags/h80/vu.png' },
  { name: 'Vatican City', flag: 'https://flagpedia.net/data/flags/h80/va.png' },
  { name: 'Venezuela', flag: 'https://flagpedia.net/data/flags/h80/ve.png' },
  { name: 'Vietnam', flag: 'https://flagpedia.net/data/flags/h80/vn.png' },
  { name: 'Yemen', flag: 'https://flagpedia.net/data/flags/h80/ye.png' },
  { name: 'Zambia', flag: 'https://flagpedia.net/data/flags/h80/zm.png' },
  { name: 'Zimbabwe', flag: 'https://flagpedia.net/data/flags/h80/zw.png' }
],
    };
  },

  async created(){
    await this.getInstaQst();
  },


  methods: {
    proxyImage(url) {
      return `https://swingi.pro/api.php?action=proxyImage&url=${encodeURIComponent(url)}`;
    },

    async saveInstaQst() {
  // Check if all required inputs are provided
  if (this.sQst && this.wAnswer2 && this.sDescription && this.cAnswer && this.wAnswer1) {
    try {
      // Call the method to add the Instagram question
      await this.addInstaQst();
      this.errorInputing = false;
    } catch (error) {
      // Handle errors from addInstaQst
      console.error('Error while saving Instagram question:', error);
      this.errorInputing = true;
    }
  } else {
    this.errorInputing = true;
  }
},

async getInstaQst() {
  try {

    var token = localStorage.getItem('authToken');
    
    const response = await fetch(`https://swingi.pro/api.php?action=getInstaQst&token=${encodeURIComponent(token)}`, {
      method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (data.error) {
      console.error('Erreur:', data.error, 'Détails:', data.details);
    } else {
      
      if (data.successe === false){
        this.$emit('insta-active', false);
        localStorage.setItem('insta-Active', false);
      } else {
        localStorage.setItem('insta-Active', true);
          this.instaQst = data.data;
          this.sQst = this.instaQst[2];
          this.sDescription = this.instaQst[1];
          this.cAnswer = this.instaQst[3];
          this.wAnswer1 = this.instaQst[4];
          this.wAnswer2 = this.instaQst[5];
          this.dDContent = this.instaQst[6];
          if (this.instaQst[7] == '0'){
            this.$emit('insta-active', false);
            this.isChecked = false;
          } else if (this.instaQst[7] == '1') {
            this.$emit('insta-active', true);
            this.isChecked = true;
          }

          console.log('this is instaQst:', this.isChecked);
        
      }
    }


  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }
},

async cancel() {
  this.isInstaEdit = false;
      this.$emit('cancel', false);
},

isAccepted(value) {
      
      this.isChecked = value;

    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen; // Toggle dropdown visibility
    },
    updateContent(value) {
      this.dDContent = value; // Update the dropdown content
      this.isDropdownOpen = false; // Close the dropdown after selection
    },

    editInsta() {
      this.isInstaEdit = true;
      this.isInstaChange = false;
      this.$emit('instagram-edit');
    },

    changeInsta() {
      this.isInstaEdit = false;
      this.isInstaChange = true;
      this.$emit('instagram-change');
    },

    autoResizeInput() {
      const input = this.$refs.autoResizeInput;
      input.style.height = 'auto';
      input.style.height = (input.scrollHeight) + 'px';
    },

    async addInstaQst() {
      var value = 0;
      if (this.isChecked === false) {
        value = 0;
        localStorage.setItem('insta-Active', false);
      } else {
        value = 1;
        localStorage.setItem('insta-Active', true);
      }
  const userDetails = {
    token: localStorage.getItem('authToken'),
    dscr: this.sDescription,
    qst: this.sQst,
    answer1: this.cAnswer,
    answer2: this.wAnswer1,
    answer3: this.wAnswer2,
    country: this.dDContent,
    activ: value,
    dayTime: new Date().toISOString()
  };

  console.log(userDetails);

  try {
    var response = await fetch('https://swingi.pro/api.php?action=saveInstaQst', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userDetails)
    });

    

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    
    console.log('Réponse POST:', data);

    if (data.error) {
      console.error('Erreur:', data.error, 'Détails:', data.details);
    } else {
      console.log('Succès:', data.message);
    }

    this.canGo = true;
  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }
}
  },

  mounted() {
    this.$nextTick(() => {
      const input = this.$refs.autoResizeInput;
      if (input) {
        input.setAttribute('style', 'height:' + (input.scrollHeight) + 'px;overflow-y:hidden;');
      }
    });
  }
}
</script>

<style>

text{
  width: 100%;

}

input[type="wrong-answer"] {
  margin: 5px;
  width: calc(100% - 10px);
  height: 40px;
  min-height: 40px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: rgb(255, 217, 217);
  transition: all 0.3s ease;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: vertical;
  overflow: hidden;
  font-family: 'Josefin Sans', sans-serif;
}

input[type="wrong-answer"]:focus {
  border-color: #3498db;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.2);
  font-family: 'Josefin Sans', sans-serif;
  padding-left: 5px;
}

input[type="wrong-answer"]::placeholder {
  color: #aaaaaa;
}

input[type="correct-answer"] {
  margin: 5px;
  width: calc(100% - 10px);
  height: 40px;
  min-height: 40px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: rgb(212, 240, 206);
  transition: all 0.3s ease;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: vertical;
  overflow: hidden;
  font-family: 'Josefin Sans', sans-serif;
}

input[type="correct-answer"]:focus {
  border-color: #3498db;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.2);
  font-family: 'Josefin Sans', sans-serif;
  padding-left: 5px;
}

input[type="correct-answer"]::placeholder {
  color: #aaaaaa;
}

input[type="text"] {
  margin: 5px;
  width: calc(100% - 10px);
  height: 40px;
  min-height: 40px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: vertical;
  overflow: hidden;
  font-family: 'Josefin Sans', sans-serif;
}

input[type="text"]:focus {
  border-color: #3498db;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.2);
  font-family: 'Josefin Sans', sans-serif;
  padding-left: 5px;
}

input[type="text"]::placeholder {
  color: #aaaaaa;
}

/* Basic styling for the dropdown */
.dropdown {
  margin: 5px;
  width: calc(100% - 10px);
  position: relative;
  display: inline-block;
}

.dropbtn {
  margin: 5px;
  width: calc(100% - 10px);
  height: 30px;
  background-color: #3498db; /* Blue background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for the button */
  font-size: 16px; /* Font size */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background transition */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Josefin Sans', sans-serif;
}

.dropbtn:hover {
  background-color: #2980b9; /* Darker blue on hover */
}

.dropdown-content {
  margin: 5px;
  width: calc(100% - 10px);
  display: none; /* Hidden by default */
  position: absolute; /* Position it below the button */
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  border-radius: 10px;
  min-width: 200px; /* Minimum width */
  max-height: 300px; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Shadow effect */
  z-index: 1; /* Ensure it appears above other elements */
  border-radius: 5px; /* Rounded corners */
}

.dropdown-content a {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  color: black; /* Text color */
  padding: 12px 16px; /* Padding for links */
  text-decoration: none; /* No underline */
  font-family: 'Josefin Sans', sans-serif;
  transition: background-color 0.3s; /* Smooth background transition */
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: #f1f1f1; /* Light gray on hover */
}

/* Show the dropdown content when the button is clicked */
.dropdown-content {
  display: block; /* Show the dropdown */
}

.flag-icon {
  width: 20px; /* Width of the flag icon */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between flag and text */
}


.body3 {
  margin-block: 40px;
    width: 90%; /* Prendre toute la largeur du parent */
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(255, 255, 255, .8);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    

  }

  .dscr {
    margin: 5px;
    width: 100%;
    font-family: 'Josefin Sans', sans-serif;
  }

  .structure-box {
    margin-top: 30px;
  }

  .structure-box:hover {
    background-color: rgba(95, 127, 255, 0.1);
    backdrop-filter: blur(2px);
    margin-top: 30px;
  }

  .structure-box2 {
    height: 50px;
    display: flex;
    justify-content: center;
  }

    .short-text {
    margin: 2px;
    width: 100%;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    color: gray;
  }

  .errorText {
    width: 100%;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    color: #ff5555;
    display: flex;
    justify-content: center;
  }

  .res-1 {
    margin: 5px;
    width: calc(100% - 10px);
    height: 25px;
    background-color: #ffffff60;
    font-family: 'Josefin Sans', sans-serif;
  }

  .instaTitle {
    width: 100px;
    margin: 5px;
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */
    font-weight: bold;
    background-image: linear-gradient(90deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .userTitle {
    display: flex; /* Définit un conteneur flex */
    align-items: center; /* Centre le texte verticalement */
    justify-content: center; /* Centre le texte horizontalement */
    text-align: center; /* Centrer le texte horizontalement à l'intérieur de l'élément */
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */
    height: 100%; /* Assurez-vous que le conteneur a une hauteur définie */

  }


  .profileImage{
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%; /* Rend l'image circulaire */
  }

  .viewStatBox {
    margin: 5px;
    height: 20px;

  }
  .instaViewBox {
    display: flex; /* Définit un conteneur flex */
    align-items: center; /* Centre verticalement les éléments dans le conteneur */
    margin: 5px;
    height: 40px;
    background-color: #e3f5ff;
  }

  .instaImage{
    margin-right: 5px;
    height: 40px;
    width: 40px;

  }
  .instaName{
    margin-right: 5px;
    height: 40px;
    width: 90%;

  }

  .instaBox {
    margin: 10px;
    height: 75px;
    width: 50%;
    background-color: #cbecff;
  }



  .text3 {
    width: 60%;
    font-size: 10px; /* Ajuster la taille de la police selon vos besoins */
    color: black; /* Ajuster la couleur du texte selon vos besoins */
  }




  .boxBtn3 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .btn3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */

  }
  
  .btn3:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }


</style>