<template>
    <div class="body1">
        

      <div class="profile-container">
        <div v-if="notificationVisible" class="notification">{{ notificationMessage }}</div>
        <div v-if="errorVisible" class="notification-error">{{ errorMessage }}</div>
        <div class="profile-box-image">
          <img 
            :src="profileImage" 
            alt="Profile Image" 
            @click="triggerFileInput"
            class="profile-image"
          />
          <input 
            type="file" 
            ref="fileInput" 
            @change="uploadImage" 
            style="display: none;"
          />
        </div>
      </div>
          
          <div class="user-title">

            <div class="user-name">
                {{ this.userName }}
            </div>
            
          </div>

          <div class="email-title">

            <div>
              {{ this.email }}
          </div>
            
          </div>

          <div class="phone-title">

            <div>
              {{ this.phoneNumber }}
          </div>
            
          </div>



    </div>
</template>



<script>

export default {
    name: "UserCardInfo",

    data() {
    return {
      profileImage: '/g.png', // URL par défaut de l'image

      userName: '',
      email: '',
      phoneNumber: '',
      notificationMessage: '',
      notificationVisible: false,
      errorVisible: false,
      token: localStorage.getItem('authToken'), // Assurez-vous de définir le jeton approprié
      notification: '', // Notification message
      errorMessage: '', // Error message
      notificationType: '' // 'success' or 'error'
    };
  },
  created() {
    this.searchUserImage();
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click(); // Ouvre le sélecteur de fichiers
    },

    async searchUserImage(){
      const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        console.log('found Token : ', info.data[foundTokenIndex].profileImage);
        

        if (this.foundTokenIndex !== -1) {
          
          if (info.data[foundTokenIndex].profileImage !== '' && info.data[foundTokenIndex].profileImage !== null) {
            const timestamp = new Date().getTime();
            this.profileImage = `${info.data[foundTokenIndex].profileImage}?t=${timestamp}`;
            localStorage.setItem('proImage', this.profileImage);
          } else {
            const timestamp = new Date().getTime();
            this.profileImage = `/g.png?t=${timestamp}`;
            localStorage.setItem('proImage', this.profileImage);
          }
          if (info.data[foundTokenIndex].userName !== '' && info.data[foundTokenIndex].userName !== null) {
            this.userName = info.data[foundTokenIndex].userName;
          } else {
            this.userName = info.data[foundTokenIndex].token;
          }

          if (info.data[foundTokenIndex].email !== '' && info.data[foundTokenIndex].email !== null) {
            this.email = info.data[foundTokenIndex].email;
          } else {
            this.email = 'Add Your Email';
          }

          if (info.data[foundTokenIndex].phoneNumber !== '0' && info.data[foundTokenIndex].phoneNumber !== null) {
            this.phoneNumber = info.data[foundTokenIndex].phoneNumber;
          } else {
            this.phoneNumber = 'Add Your Phone Number';
          }
          
        }
    },
    async uploadImage() {
      const fileInput = this.$refs.fileInput;
      const formData = new FormData();
      formData.append('fileToUpload', fileInput.files[0]);
      formData.append('token', this.token);

      try {
        const response = await fetch('https://swingi.pro/api.php?action=uploadImage', {
          method: 'POST',
          body: formData
        });

        // Lire la réponse en tant que texte pour le débogage
        const textResponse = await response.text();
        console.log('Response Text:', textResponse);

        // Convertir la réponse en JSON
        const data = JSON.parse(textResponse);
        if (data.success) {
          
          const timestamp = new Date().getTime();
          this.profileImage = `${data.imageUrl}?t=${timestamp}`;// Mettez à jour l'URL de l'image
          this.showNotification('Image uploaded successfully');
          await this.updateUser();
          console.log('Donneés mises à jour');
        } else {
          console.error('Error uploading image:', data.error);
          this.showError(`Error uploading image: ${textResponse.error}`);
        }
      } catch (error) {
        console.error('Error:', error);
        this.showError(`Error uploading image: ${error}`);
      }
    },
    showError(message) {
      this.errorMessage = message;
      this.errorVisible = true;

      // Masquer la notification après 3 secondes
      setTimeout(() => {
        this.errorVisible = false;
      }, 5000);
    },
    showNotification(message) {
      this.notificationMessage = message;
      this.notificationVisible = true;

      // Masquer la notification après 3 secondes
      setTimeout(() => {
        this.notificationVisible = false;
      }, 5000);
    },



    async updateUser() {
      console.log(this.token, this.profileImage);
      const updateData = {
        token: this.token,
        field: 'profileImage',
        value: `https://swingi.pro${this.profileImage}`,// Assignez l'URL de l'image téléchargée
      };

      try {
        const response = await fetch('https://swingi.pro/api.php?action=updateUserDetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        });
        const data = await response.json();
        if (data.success) {
          console.log('User updated successfully');
        } else {
          console.error('Error updating user:', data.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }
}


</script>

<style>




.body1 {
    
    width: 30%; /* Prendre toute la largeur du parent */
    min-width: 300px;
    border-radius: 6px;
    background: rgb(255, 243, 243);
    border: 1px solid rgba(255, 255, 255, .8);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    

  }

  .profile-container {
    position: relative;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  
  .notification {
    position: absolute;
    top: -40px; /* Ajuster pour placer le message au-dessus de l'image */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(115, 220, 115, 0.316);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    animation: fadeOut 5s forwards;
  }

  .notification-error {
    position: absolute;
    top: -40px; /* Ajuster pour placer le message au-dessus de l'image */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(224, 100, 100, 0.316);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    animation: fadeOut 5s forwards;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  .profile-box-image{
    position: relative;

  }

  .profile-image {
    cursor: pointer;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid #ccc;

    
  }

  .user-title {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 5px;
    font-family: 'Playwrite CU', sans-serif;
    font-size: 18px; /* Ajuster la taille de la police selon vos besoins */

  }

  .email-title {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 2px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;

  }

  .phone-title {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 2px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;

  }

  .user-name {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 60%;
    
    color: black; /* Ajuster la couleur du texte selon vos besoins */
    
    
  }

  .title {

    padding: 10px;
    font-size: 12px; /* Ajuster la taille de la police selon vos besoins */
    color: black; /* Ajuster la couleur du texte selon vos besoins */

  }

  .text {
    width: 60%;
    color: black; /* Ajuster la couleur du texte selon vos besoins */

  }

  .child {
    width: 100%;
    height: 40px;

    padding: 10px;
    display: flex;
    justify-self: center;
    align-items: center;

  }

  .input {
    width: 40%;
    height: 35px;

  }
  
  .input input:hover, 
  .input input:focus {
    width: 100%;
    height: 30px;
    background: rgba(255, 255, 255, .9);
    border: none;
    outline: none;
    border-radius: 2px;
    padding-left: 2px;
    font-size: 12px; /* Changez la taille de la police ici */
  }

  .input input:not(:focus) {
    padding-left: 2px;
    font-size: 12px; /* Taille de police lorsque l'élément perd le focus */
  }
  
  .input input::placeholder {
    padding-left: 2px;
    color: gray;
    font-size: 12px; /* Changez la taille de la police du placeholder ici */
  }



  .boxBtn {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
  }
  
  .btn:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }


  .input input {
    width: 100%;
    height: 30px;
    background: rgba(212, 212, 212, 0.6);
    border: none;
    outline: none;
    border-radius: 2px;
    font-size: 16px;

  }

</style>