import { createRouter } from "vue-router"
import { createWebHistory } from "vue-router"
import HomeFeed from "../views/HomeFeed.vue"
import UserDetails from "../views/UserDetails.vue"
const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeFeed,
    },
    {
        path: '/userDetails/:id',
        name: 'UserD',
        component: UserDetails,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router