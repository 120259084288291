<template>
  <div>
    <!-- Conteneur pour votre publicité -->
    <div ref="adContainer"></div>
    <div v-if="adBlockDetected" class="adblock-notification">
      <p>Pleas Add Swingi.pro To Your Ads Blocker White Liste, & Earn More Swingi Point.</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AdScript1',
  data() {
    return {
      adBlockDetected: false,
    };
  },
  mounted() {

    this.loadAds();
    this.checkForAdBlocker();
},

  methods: {

    

    checkForAdBlocker() {
      setTimeout(() => {
            // Si la balise de test `.adsbygoogle` est masquée ou n'est pas chargée, c'est qu'un adblocker est actif
            const adTest = this.$refs.adContainer;
      if (adTest && adTest.offsetHeight === 0) {
        this.adBlockDetected = true; // Détection d'un adblocker
        console.log('blocked', adTest.offsetHeight);
      } else {
        this.adBlockDetected = false;
        this.updateSwingiPoint();
        console.log('free', adTest.offsetHeight);
      }
    }, 5000);

    },

    async loadAds(){
      if (!this.adBlockDetected) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = '//www.topcreativeformat.com/1feb43c815c667e76c42dbdf8626e6de/invoke.js';

      // Ajouter le script au conteneur référencé dans le template
      this.$refs.adContainer.appendChild(script);

      // Configuration du script publicitaire
      window.atOptions = {
        key: '1feb43c815c667e76c42dbdf8626e6de',
        format: 'iframe',
        height: 60,
        width: 468,
        params: {}
      };
      

       // Mise à jour des Swingi Points
    }
    },

    async updateSwingiPoint() {
  console.log('Clicked');
  try {
    var token = localStorage.getItem('authToken');

    const response = await fetch(`https://swingi.pro/api.php?action=updateBankValue`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok: ' + response.statusText);
    }

    const data = await response.json();
    console.log('Response data:', data);

    if (!data.success) {
      console.error('Error:', data.message);
    } else {
      
      this.$emit('newSPoint', data.newSPoint);
      console.log('Execution1: ', data.newSPoint);
    }

  } catch (error) {
    console.error('Error during fetch:', error);
  }
},
  }
};
</script>

<style scoped>
.adblock-notification {
  background-color: #ffcc00;
  color: #333;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
}

.adblock-notification p {
  margin: 0;
}
</style>
