<template>
  <div>
    <HeaderNavbar v-if="!isMobile" :userId="currentUser.id" :username="currentUser.name" :swingiPoint="sPoint"/>
    <MobileNavbar v-if="isMobile" :userId="currentUser.id" :username="currentUser.name" :swingiPoint="sPoint" />
    
    <RouterView />
  </div>
</template>

<script>
import HeaderNavbar from "./components/HeaderNavbar.vue";
import MobileNavbar from "./components/MobileNavbar.vue"; // Nouvelle barre pour mobile

export default {
  name: 'App',
  components: {
    HeaderNavbar,
    MobileNavbar,
  },
  data() {
    return {
      currentUser: {
        id: localStorage.getItem('authToken') ? localStorage.getItem('authToken') : '0',
        name: localStorage.getItem('userName') ? localStorage.getItem('userName') : 'none'
      },
      isMobile: false,
      sPoint: localStorage.getItem('newSPoint') ? localStorage.getItem('newSPoint') : '0',
    };
  },
  mounted() {
    // Détecter la taille de l'écran
    this.checkScreenSize();
    
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // Détecte si l'écran est inférieur ou égal à 768px (taille mobile)
    },

  }
};
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

</style>

