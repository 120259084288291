import { initializeApp } from "firebase/app";
import { getAuth ,GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA31ipPzxWx5rwVoFjSomhHD6veJsR2Vcg",
  authDomain: "swingi-f08b6.firebaseapp.com",
  projectId: "swingi-f08b6",
  storageBucket: "swingi-f08b6.appspot.com",
  messagingSenderId: "557449151701",
  appId: "1:557449151701:web:202b8b3d9ef54e4eebce5c",
  measurementId: "G-6K9HVBNL2Z"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.languageCode = "en";
const provider = new GoogleAuthProvider();

export { auth, provider };