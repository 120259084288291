<template>
  <div class="column">
    <div class="box1">
      <div class="pImage">
          <img class="shortImage" src="/g.png" alt="Thumbnail"/>
      </div>
      <div>
          {{instaName[0]}}
      </div>
  </div>
  <div class="box1">
      <div class="pImage">
          <img class="shortImage" src="/g.png" alt="Thumbnail"/>
      </div>
      <div>
          {{PBox2}}
      </div>
  </div>


  <div class="box1">
      <div class="pImage">
          <img class="shortImage" src="/g.png" alt="Thumbnail"/>
      </div>
      <div>
          {{PBox3}}
      </div>
  </div>
  <div class="box1">
      <div class="pImage">
          <img class="shortImage" src="/g.png" alt="Thumbnail"/>
      </div>
      <div>
          {{PBox4}}
      </div>
  </div>
  </div>




  </template>
  
  <script>
  export default {
    name: "instaJob1Mob",
    props: {
    instaName: {
        type: Array,
    required: false,
    validator(value) {
      // Vérifier que chaque élément du tableau est une chaîne de caractères
      return value.every(item => typeof item === 'string');
    }
    },
    instaImage: {
        type: Array,
    required: false,
    validator(value) {
      // Vérifier que chaque élément du tableau est une chaîne de caractères
      return value.every(item => typeof item === 'string');
    }
    },
    
  },
    data() {
      return {
        PBox1: '',
        PBox2: '',
        PBox3: '',
        PBox4: '',
      };
    },

    created() {

  
  
    },
    methods: {
    
  
  
  
    }
  };
  </script>
  
  <style scoped>

  .column {
    width: 100%;
    display: flex;
    flex-direction: column; /* Assure que les éléments sont alignés verticalement */
    align-items: center; /* Centre les éléments horizontalement (optionnel) */
    height: 100vh; /* Assure que la colonne occupe toute la hauteur de la fenêtre */
  }
  

  .box1 {
    width: 400px;
    height: 50px;
    min-width: 400px;
    background-color: rgb(201, 201, 201);
    margin-inline: 10px;
    margin-block: 5px;
    display: flex;
    align-items: center;
  }

  .pImage {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    margin-inline: 5px;
  }

  .shortImage {
    border-radius: 50%;
    object-fit: cover;
  }

  </style>
  