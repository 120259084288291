<template>
    <div :class="[isHovering ? 'hover-class' : 'normal-class', 'w-1/2 m-2 p-2 rounded-xl shadow-lg flex items-center space-x-4 text-white']"
         @mouseover="isHovering = true"
         @mouseleave="isHovering = false"
         v-if="userInfo">
        <img class="h-16" src="/g.png" alt="Thumbnail"/>
        <h2>{{ userInfo.name }}</h2>
    </div>
    
    <div v-else>
        <p>Loading user information...</p>
    </div>
    <li class="list-none h-1">

    </li>
</template>

<script>
export default {
    name: "UserCard",
    props: {
        userInfo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isHovering: false
        };
    }
};
</script>

<style scoped>
.normal-class {
    
    height: 2.5rem; /* h-20 equivalent */
    background-color: #833ab4;
}

.hover-class {
    
    height: 2.5rem; /* h-10 equivalent */
    background: linear-gradient(45deg, #fd1d1d60, #fcb04560, #833ab460, #fd1d1d60);
    background-size: 300% 300%;
    animation: gradientAnimation 5s ease infinite;
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}
</style>
