<template>
    <div class="body">
      <div class="wrapper">
        <form @submit.prevent="btnClick">
          <h1>Login</h1>
          <div class="important">
            <p>You have an ads blocker? Pleas think to add swingi.pro to your adblock white list.</p>
        </div>
          
          <div class="input-box">
            <input v-model="email" type="email" placeholder="Email" required/>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 85, 85, 1);">
              <path d="M19 2H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h4l3 3 3-3h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-7 3c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zM7.177 16c.558-1.723 2.496-3 4.823-3s4.266 1.277 4.823 3H7.177z"></path>
            </svg>
          </div>
          <div class="input-box">
            <input :type="passwordFieldType" v-model="password" placeholder="Password" required/>
            <svg v-if="passwordFieldType === 'password'" @click="togglePasswordVisibility" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 85, 85, 1);">
              <path d="M12 2C9.243 2 7 4.243 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9V7zm4 10.723V20h-2v-2.277a1.993 1.993 0 0 1 .567-3.677A2.001 2.001 0 0 1 14 16a1.99 1.99 0 0 1-1 1.723z"></path>
            </svg>
            <svg v-if="passwordFieldType === 'text'" @click="togglePasswordVisibility" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);"><path d="M18 10H9V7c0-1.654 1.346-3 3-3s3 1.346 3 3h2c0-2.757-2.243-5-5-5S7 4.243 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2zm-7.939 5.499A2.002 2.002 0 0 1 14 16a1.99 1.99 0 0 1-1 1.723V20h-2v-2.277a1.992 1.992 0 0 1-.939-2.224z"></path></svg>
            
          </div>
  
          <div class="register-link">
            
            <a href="#"><button class="link-button">Forgot password?</button></a>
          </div>
          <button type="submit" @click="logInWithEmail" class="btn">Login</button>
          

        </form>
        
        <div>
          <button @click="googleLogIn" class="google-signin-button">Google Login</button>

        </div>
        <div class="register-link">
          <p>Don't have an account? <button @click="login" class="link-button">Register</button></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import { auth, provider } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';



  export default {
    name: "LoginCard",

    data() {
      return {
        email: '',
      password: '',
      confirmPassword: '',
        passwordFieldType: 'password',
        
        remember: false,

      };
    },
    methods: {
      togglePasswordVisibility() {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';  
      },

      async logInWithEmail() {
        try {
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;
        console.log('User Loged');
        localStorage.setItem('authToken', user.uid);
        localStorage.setItem('userEmail', user.email);
        localStorage.setItem('userName', user.displayName);
        this.saveUserDetails(user.uid, user.displayName, null, user.email, '0', '0');
        console.log('stored:', 'id: ',user.uid, 'email: ',user.email);
        window.location.href = this.$router.resolve({ name: 'Home' }).href;
      } catch (error) {
        console.error('Error registering user:', error.message);
        // Handle different types of errors
        switch (error.code) {
          case 'auth/email-already-in-use':
            alert('The email address is already in use by another account.');
            break;
          case 'auth/invalid-email':
            alert('The email address is not valid.');
            break;
          case 'auth/operation-not-allowed':
            alert('Email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.');
            break;
          case 'auth/weak-password':
            alert('The password is too weak.');
            break;
          default:
            alert('Error registering user: ' + error.message);
        }
      }
    },

    googleLogIn() {
      signInWithPopup(auth, provider)
        .then((result) => {
          const user = result.user;
          console.log('User Loged');
          localStorage.setItem('authToken', user.uid);
    localStorage.setItem('userEmail', user.email);
    localStorage.setItem('userName', user.displayName);
    this.saveUserDetails(user.uid, user.displayName, null, user.email, '0', '0');
    console.log('stored:', 'id: ',user.uid, 'email: ',user.email);
    window.location.href = this.$router.resolve({ name: 'Home' }).href;
        })
        .catch((error) => {
          console.log(error);
        });
    },
      login() {
      this.$emit('login-click');
    },

    async saveUserDetails(token, userName, profileImage, email, phoneNumber, insta) {
  const userDetails = {
    token: token,
    userName: userName,
    profileImage: profileImage,
    email: email,
    phoneNumber: phoneNumber,
    insta: insta,
    dayTime: new Date().toISOString()
  };


  const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const info = await response.json();
        const foundTokenIndex = info.data.data.findIndex(item => item.token === token);
        console.log('info1 : ', foundTokenIndex, token);

        if (foundTokenIndex !== -1) {
          await fetch('http://swingi.pro/api.php?action=saveUserDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userDetails)
      });
        }

}, 


    }
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles personnalisés si nécessaire */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Verdana, sans-serif;
  }
  
  .body {
    width: 380px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .important {
    margin-top: 10px;
    width: 100%;
    
    font-size: 12px;
    text-align: center;
    color: #ff5555;
    
  }



  .wrapper {
    width: 380px;
    
    border-radius: 10px;
    padding: 15px 20px;
  }
  
  .wrapper h1 {
    font-weight: bold;
    font-size: 36px;
    text-align: center;
  }
  
  .wrapper .input-box {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 20px 0;
  }

  .google-signin-button {
    margin-top: 10px;
    width: 100%;
    height: 45px;
    outline: none;
    border-radius: 6px;
    background: rgba(255, 255, 255, .5);
    border: 2px solid rgba(255, 255, 255, .8);
    backdrop-filter: blur(2px);
    cursor: pointer;
    font-size: 16px;
    color: black;
    font-weight: bold;
  }
  

  .google-signin-button:hover {
    border-radius: 8px;
    padding: 2px;
    height: 45px;
    color: white;
      background: linear-gradient(45deg, #4285F480, #F4B40080, #0F9D5880);
      background-size: 300% 300%;
      animation: gradientAnimation 3s ease infinite;
  }
  
  @keyframes gradientAnimation {
      0% { background-position: 0% 50%; }
      50% { background-position: 100% 50%; }
      100% { background-position: 0% 50%; }
  }
  
  .input-box input {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .3);
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 6px;
    font-size: 16px;
    padding: 20px 45px 20px 20px;
  }

  .input-box input:hover, input:focus {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .9);
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 6px;
    font-size: 16px;
    padding: 20px 45px 20px 20px;
  }
  
  .input-box input::placeholder {
    color: gray;
  }
  
  .input-box svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }
  
  .wrapper .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
  }
  
  .remember-forgot label input {
    accent-color: #fff;
    margin-right: 3px;
  }
  
  .remember-forgot a {
    color: gray;
    text-decoration: none;
  }
  
  .remember-forgot a:hover {
    text-decoration: underline;
  }
  
  .wrapper .btn {
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: black;
    font-weight: bold;
  }
  .btn:hover {
    border-radius: 8px;
    padding: 2px;
    height: 45px;
    color: white;
      background: linear-gradient(45deg, #fd1d1d80, #fcb04580, #833ab480, #fd1d1d80);
      background-size: 300% 300%;
      animation: gradientAnimation 5s ease infinite;
  }
  
  .wrapper .register-link {
    font-size: 12px;
    text-align: center;
    margin: 20px 0 15px;
  }
  
  .link-button {
    background: none;
    border: none;
    color: gray;
    cursor: pointer;
    text-decoration: underline;
    font-size: inherit;
  }
  
  .link-button:hover {
    color: #ff5555;
  }
  </style>
  