<template>
  <div class="bg">
    <li class="list-none h-20"></li>

    <!-- Conteneur de la publicité -->
    <div id="ad-container">
      <AdScript1 @click="updateSwingiPoint"/>
    </div>

    <div class="sm:px-32 flex flex-wrap justify-center">
      <UserCard v-for="userD in usersD" :key="userD.id" :userInfo="userD" />
    </div>
  </div>

  <div v-if="!isMobile">
    <instaJob1 :instaName="instaName" :instaImage="['job']"/>
  </div>
  <div v-if="isMobile">
    <instaJob1Mob :instaName="instaName" :instaImage="['job']"/>
  </div>
</template>

<script>
import UserCard from "../components/UserCard.vue";
import AdScript1 from '../components/AdsScript1.vue';
import instaJob1 from '../components/instaJob/instaJob1.vue';
import instaJob1Mob from '../components/instaJob/instaJob1Mob.vue';

export default {
  name: "HomeFeed",
  components: {
    UserCard,
    AdScript1,
    instaJob1,
    instaJob1Mob,
  },
  props: {

  },
  data() {
    return {
      usersD: [],
      adClickHandled: false, // Flag to check if the ad click has been handled
      sPoint: 0,
      isMobile: false,
      instaName: [],
      instaImage: [],
    };
  },
  created(){
    this.getInstaJob();
  },
  mounted() {
        // Détecter la taille de l'écran
    this.checkScreenSize();
    
    window.addEventListener('resize', this.checkScreenSize);

    
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },



  methods: {


    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // Détecte si l'écran est inférieur ou égal à 768px (taille mobile)
    },

    async getInstaJob() {
  try {

    
    const response = await fetch('https://swingi.pro/api.php?action=getInstaJob', {
      method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (data.error) {
      console.error('Erreur:', data.error, 'Détails:', data.details);
    } else {
      
      if (data.success === false){
        
        console.log('this is instaQst1:', data);
      } else {
        for(var v = 0; v < data.data.length; v++){
          this.instaName.push(data.data[v].token);
          this.instaImage.push(data.data[v].country);
        }

        console.log('this is instaQst2:', data.data.length);
        
      }
    }


  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }
},


  },
};
</script>

<style>
#ad-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 5px;
}


</style>
